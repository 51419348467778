import httpService, { baseURL } from "../../utility/http"
import { toast } from "react-toastify"
import { apiCall } from "./actions"
import { unAuthorize } from "../auth/actions"

const activeRequests = {} // Object to store active API requests and their corresponding toast IDs
let unauthorizedToastShown = false // Flag to track if the 401 toast message has been shown

const apiMiddleware = ({ dispatch }) => (next) => (action) => {
    if (action.type !== apiCall.type) return next(action)

    const {
        url,
        onSuccess,
        onError,
        method,
        data: semiFinal,
        isSuccessToast,
        requestCompleted,
        successMessage,
        isSuccess,
        isFormData
    } = action.payload

    let data = null
    if (isFormData) {
        const entries = Object.entries(semiFinal)
        data = new FormData()
        for (const [key, value] of entries) {
            data.append(key, value)
        }
    } else {
        data = semiFinal
    }

    const toastId = activeRequests[url]

    if (toastId) {
        return
    }

    httpService
        ._request({ baseURL, url, method, data })
        .then((response) => {
            if (requestCompleted) dispatch({ type: requestCompleted, payload: true })

            if (response.status === 200 && response.data.statusCode === 200) {
                unauthorizedToastShown = false
                if (isSuccessToast) {
                    successMessage ? toast.success(successMessage) : toast.success(response.data.message)
                }
                if (onSuccess) {
                    dispatch({ type: onSuccess, payload: response.data })
                }
                if (isSuccess) {
                    dispatch({ type: isSuccess, payload: true })
                }
            } else {
                // Handle non-200 status codes
                const errorMessage = response.data.message || 'Something went wrong'
                toast.error(errorMessage)
                if (onError) dispatch({ type: onError, payload: response.data })
            }
        })
        .catch((error) => {
            if (requestCompleted) dispatch({ type: requestCompleted, payload: true })

            if (error.response) {
                // Handle HTTP error responses
                switch (error.response.status) {
                    case 401:
                        if (!unauthorizedToastShown) {
                            toast.error("Session expired. Please log in again.")
                            unauthorizedToastShown = true
                            dispatch(unAuthorize())
                        }
                        break

                    case 400:
                        // Handle Bad Request with proper message from backend
                        const errorMessage = error.response.data.message || 'Invalid request'
                        toast.error(errorMessage)
                        if (onError) dispatch({ type: onError, payload: error.response.data })
                        break

                    default:
                        // Handle other errors
                        const message = error.response.data.message || error.message || 'Something went wrong'
                        toast.error(message)
                        if (onError) dispatch({ type: onError, payload: error.response.data })
                }
            } else {
                // Handle network errors or other issues
                toast.error(error.message || 'Network error occurred')
                if (onError) dispatch({ type: onError, payload: error })
            }
        })
        .finally(() => {
            delete activeRequests[url]
        })
}

export default apiMiddleware
