// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import {clearGroupOrder} from "../../utility/Utils"
// ** Import action type constants
import {
  OTP_REQUEST_SUCCESS,
  OTP_REQUEST_FAIL,
  OTP_SUBMIT_SUCCESS,
  OTP_SUBMIT_FAIL
} from './actions' // Adjust the import path to point to where your action type constants are defined.

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window?.localStorage?.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    isLoading: false,
    isRequestCompleted: false,
    isTokenVerified: null,
    isPasswordReset: false,
    otpRequested: false,
    otpRequestError: null
  },
  reducers: {
    handleLogin: (state, action) => {
      console.log('action.payload', action.payload)
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
      //localStorage.removeItem('cartItems')
      localStorage.removeItem('package')
      localStorage.removeItem('selectedCateringPackage')
      //localStorage.removeItem('restaurantId')
      //localStorage.removeItem('selectedCategoryId')
      localStorage.removeItem('contactForOtp')
      //localStorage.removeItem('fullFilmentType')
      localStorage.removeItem('isMemberPkg')
      localStorage.removeItem('membershipData')
      //localStorage.removeItem('pickUpAt')
      localStorage.removeItem('redeemCode')
      //localStorage.removeItem('selectedPlaceForApi')
      localStorage.removeItem('userInput')
      localStorage.removeItem('resDelivery')
      localStorage.removeItem('contactForOtp')
      localStorage.removeItem('password')
      localStorage.removeItem('note')
      clearGroupOrder()
    },
    setRequestCompleted: (state, action) => {
      state.isRequestCompleted = action.payload
    },
    setTokenVerified: (state, action) => {
      state.isTokenVerified = action.payload
    },
    setTokenVerifiedFalse: state => {
      state.isTokenVerified = false
    },
    setPasswordReset: (state, action) => {
      state.isPasswordReset = action.payload
    },
    // Add new reducer cases for the OTP actions
    [OTP_REQUEST_SUCCESS]: (state) => {
      console.log('STATEOTP', state)
      state.otpRequested = true
      state.otpRequestError = null
      // You can set isLoading to false or handle it as per your UI logic
      state.isLoading = false
    },
    [OTP_REQUEST_FAIL]: (state, action) => {
      state.otpRequested = false
      state.otpRequestError = action.payload
      state.isLoading = false
    },
    [OTP_SUBMIT_SUCCESS]: (state, action) => {
      // Assuming action.payload has the userData and token
      state.userData = action.payload.userData
      state[config.storageTokenKeyName] = action.payload.token
      localStorage.setItem('userData', JSON.stringify(action.payload.userData))
      localStorage.setItem(config.storageTokenKeyName, action.payload.token)
      state.otpRequested = false
      state.isLoading = false
    },
    [OTP_SUBMIT_FAIL]: (state, action) => {
      state.otpRequestError = action.payload
      state.isLoading = false
    }
  }
})

export const {
  handleLogin,
  handleLogout,
  setPasswordReset,
  setRequestCompleted,
  setTokenVerified,
  setTokenVerifiedFalse
} = authSlice.actions

export default authSlice.reducer
